import {
  AttributionWindow,
  GroupBy,
  Sort,
  SortBy,
  Timeframe,
} from "~/types/shared";
import { AdsMetric, AdMetricsFilter } from "~/types/Metrics";
import { GetOverrides } from "~/types/AnyCreativeReport";
import { LaunchReportCategory } from "~/types/LaunchReport";

export const useQuery = () => {
  const router = useRouter();
  const route = useRoute();

  const updateQuery = async (
    query: Record<string, string | null | undefined>,
  ) => {
    await router.push({
      path: route.path,
      query: {
        ...route.query,
        ...query,
      },
    });
  };

  const getOverridesQuery = <T extends string | number>(
    input: GetOverrides<T>,
  ) => {
    return {
      timeframe: input.timeframe ?? undefined,
      startDate: input.startDate ?? undefined,
      endDate: input.endDate ?? undefined,
      launchTimeframe: input.launchTimeframe ?? undefined,
      launchStartDate: input.launchStartDate ?? undefined,
      launchEndDate: input.launchEndDate ?? undefined,
      performanceTimeframe: input.performanceTimeframe ?? undefined,
      performanceStartDate: input.performanceStartDate ?? undefined,
      performanceEndDate: input.performanceEndDate ?? undefined,
      selectedGroupIds: Array.isArray(input.selectedGroupIds)
        ? JSON.stringify(input.selectedGroupIds)
        : undefined,
      selectedCreativeIds: Array.isArray(input.selectedCreativeIds)
        ? JSON.stringify(input.selectedCreativeIds)
        : undefined,
      leftYAxisMetric: input.leftYAxisMetric ?? undefined,
      rightYAxisMetric: input.rightYAxisMetric ?? undefined,
      filter: input.filter != null ? JSON.stringify(input.filter) : undefined,
      globalFilter:
        input.globalFilter != null
          ? JSON.stringify(input.globalFilter)
          : undefined,
      winnerFilter:
        input.winnerFilter != null
          ? JSON.stringify(input.winnerFilter)
          : undefined,
      looserFilter:
        input.looserFilter != null
          ? JSON.stringify(input.looserFilter)
          : undefined,
      opportunitiesFilter:
        input.opportunitiesFilter != null
          ? JSON.stringify(input.opportunitiesFilter)
          : undefined,
      primaryMetric: input.primaryMetric ?? undefined,
      gridMetrics: Array.isArray(input.gridMetrics)
        ? JSON.stringify(input.gridMetrics)
        : undefined,
      tableMetrics: Array.isArray(input.tableMetrics)
        ? JSON.stringify(input.tableMetrics)
        : undefined,
      secondaryMetrics: Array.isArray(input.secondaryMetrics)
        ? JSON.stringify(input.secondaryMetrics)
        : undefined,
      sort: input.sort ?? undefined,
      creativeGroupBy: input.creativeGroupBy ?? undefined,
      reportingLevel: input.reportingLevel ?? undefined,
      attributionWindow: input.attributionWindow ?? undefined,
      sortBy: input.sortBy ?? undefined,
      creativeSort: input.creativeSort ?? undefined,
      creativeSortBy: input.creativeSortBy ?? undefined,
    };
  };

  const getTimeframeFromQuery = (fallbacks: {
    timeframe: Timeframe | null;
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    const timeframe = route.query.timeframe;
    const startDate = getStartDateFromQuery(fallbacks.startDate);
    const endDate = getEndDateFromQuery(fallbacks.endDate);
    if (timeframe == null && startDate == null && endDate == null) {
      return fallbacks.timeframe;
    }
    return timeframe as Timeframe;
  };

  const getPerformanceTimeframeFromQuery = (fallbacks: {
    timeframe: Timeframe | null;
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    const timeframe = route.query.performanceTimeframe;
    const startDate = getPerformanceStartDateFromQuery(fallbacks.startDate);
    const endDate = getPerformanceEndDateFromQuery(fallbacks.endDate);
    if (timeframe == null && startDate == null && endDate == null) {
      return fallbacks.timeframe;
    }
    return timeframe as Timeframe;
  };

  const getLaunchTimeframeFromQuery = (fallbacks: {
    timeframe: Timeframe | null;
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    const timeframe = route.query.launchTimeframe;
    const startDate = getLaunchStartDateFromQuery(fallbacks.startDate);
    const endDate = getLaunchEndDateFromQuery(fallbacks.endDate);
    if (timeframe == null && startDate == null && endDate == null) {
      return fallbacks.timeframe;
    }
    return timeframe as Timeframe;
  };

  const getStartDateFromQuery = (fallback: Date | null) => {
    const startDate = route.query.startDate;
    if (
      startDate == null &&
      route.query.timeframe == null &&
      fallback != null
    ) {
      return fallback.toISOString();
    }
    return startDate as string;
  };

  const getPerformanceStartDateFromQuery = (fallback: Date | null) => {
    const startDate = route.query.performanceStartDate;
    if (
      startDate == null &&
      route.query.performanceTimeframe == null &&
      fallback != null
    ) {
      return fallback.toISOString();
    }
    return startDate as string;
  };

  const getLaunchStartDateFromQuery = (fallback: Date | null) => {
    const startDate = route.query.launchStartDate;
    if (
      startDate == null &&
      route.query.launchTimeframe == null &&
      fallback != null
    ) {
      return fallback.toISOString();
    }
    return startDate as string;
  };

  const getEndDateFromQuery = (fallback: Date | null) => {
    const endDate = route.query.endDate;
    if (endDate == null && route.query.timeframe == null && fallback != null) {
      return fallback.toISOString();
    }
    return endDate as string;
  };

  const getPerformanceEndDateFromQuery = (fallback: Date | null) => {
    const endDate = route.query.performanceEndDate;
    if (
      endDate == null &&
      route.query.performanceTimeframe == null &&
      fallback != null
    ) {
      return fallback.toISOString();
    }
    return endDate as string;
  };

  const getLaunchEndDateFromQuery = (fallback: Date | null) => {
    const endDate = route.query.launchEndDate;
    if (
      endDate == null &&
      route.query.launchTimeframe == null &&
      fallback != null
    ) {
      return fallback.toISOString();
    }
    return endDate as string;
  };

  const getGroupByFromQuery = (fallback: GroupBy) => {
    const groupBy = route.query.groupBy;
    if (groupBy == null) {
      return fallback;
    }
    return groupBy as GroupBy;
  };

  const getPrimaryMetricFromQuery = <T>(fallback?: T) => {
    const primaryMetric = route.query.primaryMetric;
    if (primaryMetric == null) {
      return fallback;
    }
    return primaryMetric as AdsMetric;
  };

  const getLeftYAxisMetricFromQuery = <T>(fallback?: T) => {
    const leftYAxisMetric = route.query.leftYAxisMetric;
    if (leftYAxisMetric == null) {
      return fallback;
    }
    return leftYAxisMetric as AdsMetric;
  };

  const getRightYAxisMetricFromQuery = <T>(fallback?: T) => {
    const rightYAxisMetric = route.query.rightYAxisMetric;
    if (rightYAxisMetric == null) {
      return fallback;
    }
    return rightYAxisMetric as AdsMetric;
  };

  const getFiltersFromQuery = <T>(fallback?: T) => {
    const filters = route.query.filters;
    if (filters == null) {
      return fallback;
    }
    return JSON.parse(filters as string) as AdMetricsFilter[][];
  };

  const getGlobalFiltersFromQuery = <T>(fallback?: T) => {
    const globalFilter = route.query.globalFilter;
    if (globalFilter == null) {
      return fallback;
    }
    return JSON.parse(globalFilter as string) as AdMetricsFilter[][];
  };

  const getWinnerFilterFromQuery = <T>(fallback?: T) => {
    const winnerFilter = route.query.winnerFilter;
    if (winnerFilter == null) {
      return fallback;
    }
    return JSON.parse(winnerFilter as string) as AdMetricsFilter[][];
  };

  const getLooserFilterFromQuery = <T>(fallback?: T) => {
    const looserFilter = route.query.looserFilter;
    if (looserFilter == null) {
      return fallback;
    }
    return JSON.parse(looserFilter as string) as AdMetricsFilter[][];
  };

  const getOpportunitiesFilterFromQuery = <T>(fallback?: T) => {
    const opportunitiesFilter = route.query.opportunitiesFilter;
    if (opportunitiesFilter == null) {
      return fallback;
    }
    return JSON.parse(opportunitiesFilter as string) as AdMetricsFilter[][];
  };

  const getSubViewFilterFromQuery = <T>(
    launchReportCategory: LaunchReportCategory,
    fallback?: T,
  ) => {
    switch (launchReportCategory) {
      case "winner":
        return getWinnerFilterFromQuery(fallback);
      case "looser":
        return getLooserFilterFromQuery(fallback);
      case "opportunities":
        return getOpportunitiesFilterFromQuery(fallback);
      default:
        return fallback;
    }
  };

  const getSortFromQuery = <T>(fallback?: T) => {
    const sort = route.query.sort;
    if (sort == null) {
      return fallback;
    }
    return sort as Sort;
  };

  const getCreativeSortFromQuery = <T>(fallback?: T) => {
    const sort = route.query.creativeSort;
    if (sort == null) {
      return fallback;
    }
    return sort as Sort;
  };

  const getAttributionWindowFromQuery = <T>(fallback?: T) => {
    const attributionWindow = route.query.attributionWindow;
    if (attributionWindow == null) {
      return fallback;
    }
    return attributionWindow as AttributionWindow;
  };

  const getSortByFromQuery = <T>(fallback?: T) => {
    const sortBy = route.query.sortBy;
    if (sortBy == null) {
      return fallback;
    }
    return sortBy as SortBy;
  };

  const getCreativeSortByFromQuery = <T>(fallback?: T) => {
    const sortBy = route.query.creativeSortBy;
    if (sortBy == null) {
      return fallback;
    }
    return sortBy as SortBy;
  };

  const getSelectedGroupIdsFromQuery = <T>(
    fallback?: T[] | null,
    map?: (v: string) => T,
  ): T[] | null | undefined => {
    const selectedGroupIds = route.query.selectedGroupIds;
    if (selectedGroupIds == null) {
      return fallback;
    }
    const arr = JSON.parse(selectedGroupIds as string) as string[];
    return map != null ? arr.map(map) : (arr as unknown as T[]);
  };

  const getSelectedCreativeIdsFromQuery = <T>(fallback?: T) => {
    const selectedCreativeIds = route.query.selectedCreativeIds;
    if (selectedCreativeIds == null) {
      return fallback;
    }
    const stringified = JSON.parse(selectedCreativeIds as string) as string[];
    return stringified.map((id) => Number(id));
  };

  const getGridMetricsFromQuery = <T>(fallback?: T) => {
    const gridMetrics = route.query.gridMetrics;
    if (gridMetrics == null) {
      return fallback;
    }
    return JSON.parse(gridMetrics as string) as string[];
  };

  const getTableMetricsFromQuery = <T>(fallback?: T) => {
    const tableMetrics = route.query.tableMetrics;
    if (tableMetrics == null) {
      return fallback;
    }
    return JSON.parse(tableMetrics as string) as string[];
  };

  const getSecondaryMetricsFromQuery = <T>(fallback?: T) => {
    const secondaryMetrics = route.query.secondaryMetrics;
    if (secondaryMetrics == null) {
      return fallback;
    }
    return JSON.parse(secondaryMetrics as string) as string[];
  };

  return {
    updateQuery,
    getOverridesQuery,
    getTimeframeFromQuery,
    getPerformanceTimeframeFromQuery,
    getLaunchTimeframeFromQuery,
    getStartDateFromQuery,
    getPerformanceStartDateFromQuery,
    getLaunchStartDateFromQuery,
    getEndDateFromQuery,
    getPerformanceEndDateFromQuery,
    getLaunchEndDateFromQuery,
    getGroupByFromQuery,
    getPrimaryMetricFromQuery,
    getLeftYAxisMetricFromQuery,
    getRightYAxisMetricFromQuery,
    getFiltersFromQuery,
    getGlobalFiltersFromQuery,
    getWinnerFilterFromQuery,
    getLooserFilterFromQuery,
    getOpportunitiesFilterFromQuery,
    getSubViewFilterFromQuery,
    getSortFromQuery,
    getSelectedGroupIdsFromQuery,
    getSelectedCreativeIdsFromQuery,
    getGridMetricsFromQuery,
    getTableMetricsFromQuery,
    getSecondaryMetricsFromQuery,
    getAttributionWindowFromQuery,
    getSortByFromQuery,
    getCreativeSortByFromQuery,
    getCreativeSortFromQuery,
  };
};
